<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(row, wrap)
      v-flex(lg12)
        v-card.elevation-1.pa-3
          v-layout(row, wrap)
            v-flex.md6.xs12
               v-text-field.hidden-sm-and-down(flat, solo, prepend-icon='search', placeholder='Buscar auditoria', v-model='filter.search', hide-details, clearable)
            v-flex.md4.xs12
              v-autocomplete.mt-2(clearable, dense, label="Filtrar por tipo", :items="tiposQuestionarios", :disabled="!tiposQuestionarios.length", item-text="nome", item-value="id", color="gray", v-model="filter.tipo_questionario_id", name="auditado")
            v-flex.md2.xs12
              v-autocomplete.mt-2(label="Filtrar por status", :items="allStatus", :disabled="!allStatus.length", item-text="nome", item-value="id", color="gray", v-model="filter.status", name="status",:clearable="true", autocomplete, :filter="this.$root.customFilter")
                template(slot="selection", slot-scope="data")
                  span {{ data.item.nome }}
    v-layout(row, wrap)
      v-flex(lg12)
        v-card
          v-card-text.pa-0
            v-data-table.elevation-1(:headers='headers', :search='filter.search', :items='list', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading", :pagination.sync="pagination")
              template(slot='items', slot-scope='props')
                td(width="5%").text-xs-center {{ props.item.id }}
                td.text-xs-left
                  span.font-weight-bold {{ props.item.questionario.nome }}
                  p.overline {{ props.item.questionario.tipo_questionario.nome }}
                td.text-xs-left
                  span.font-weight-bold {{ props.item.auditado.identificador }}
                  p.overline {{ props.item.auditado.nome }}
                td.text-xs-left {{ props.item.usuario ? props.item.usuario.nome : "" }}
                td.text-xs-center.text-uppercase {{ props.item.data_inicio | moment(' MMM [de] YYYY') }}
                td.text-xs-center
                  v-chip(label, small, :color="['#808080', '#EC971F', '#318837', '#C9302C'][props.item.status]").text-uppercase.white--text.font-weight-bold {{ props.item.status | statusAuditoria }}
                td(width="15%").text-xs-center
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, color='success', small, :to="{ name: 'ResultadosLista', params: { id: props.item.id } }")
                      v-icon visibility
                    span Resultados
</template>

<script>
import _ from "lodash";
import AccessLock from "@/components/AccessLock";

export default {
    components: {
        AccessLock,
    },
    data() {
        return {
            filter: {
                auditado_id: null,
                tipo_questionario_id: null,
                status: null,
                search: "",
            },
            pagination: {
                sortBy: "-id",
            },
            data_inicio: false,
            data_fim: false,
            dataRange: {
                data_inicio: this.$moment().startOf("day").format("YYYY-MM-DD"),
                data_fim: this.$moment().endOf("day").format("YYYY-MM-DD"),
            },
            auditados: [],
            auditorias: [],
            tiposQuestionarios: [],
            allStatus: [
                // { id: null, nome: 'Todos'},
                { id: 1, nome: "Vigente" },
                { id: 2, nome: "Concluída" },
                { id: 3, nome: "Cancelada" },
            ],
            headers: [
                { text: "#", align: "left", value: "id" },
                {
                    text: "Auditoria",
                    align: "left",
                    value: "questionario.nome",
                },
                {
                    text: "Auditado",
                    align: "left",
                    value: "auditado.identificador",
                },
                {
                    text: "Auditor Responsável",
                    align: "left",
                    value: "usuario.nome",
                },
                {
                    text: "Data de Início",
                    align: "center",
                    value: "data_inicio",
                },
                { text: "Status", align: "center", value: "status" },
                { text: "Ações", align: "center", sortable: false },
            ],
            search: "",
            loading: false,
        };
    },
    computed: {
        dataInicio() {
            return this.dataRange.data_inicio
                ? this.$moment(this.dataRange.data_inicio).format("DD/MM/YYYY")
                : "";
        },
        dataFim() {
            return this.dataRange.data_fim
                ? this.$moment(this.dataRange.data_fim).format("DD/MM/YYYY")
                : "";
        },
        list() {
            const vm = this;
            let auditorias = _.cloneDeep(vm.auditorias);

            if (!_.isNil(vm.filter.status))
                auditorias = _.filter(auditorias, { status: vm.filter.status });
            if (vm.filter.auditado_id)
                auditorias = _.filter(auditorias, {
                    auditado_id: vm.filter.auditado_id,
                });
            if (vm.filter.tipo_questionario_id) {
                auditorias = _.filter(auditorias, (auditoria) => {
                    return (
                        auditoria.questionario.tipo_questionario_id ==
                        vm.filter.tipo_questionario_id
                    );
                });
            }
            return auditorias;
        },
        user() {
            return this.$store.getters.user;
        },
    },
    mounted() {
        this.getAuditorias();
    },
    methods: {
        setData(dataRange, mesesInicio, mesesFinal) {
            const vm = this;
            dataRange.data_inicio = vm
                .$moment()
                .add(mesesInicio, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            dataRange.data_fim = vm
                .$moment()
                .add(mesesFinal, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
        },
        async getAuditorias() {
            const vm = this;
            try {
                vm.loading = true;
                // const response = await vm.$axios.get('/auditoria', { params : { fields: "auditado,usuario,questionario", usuario_id: this.user.permissao == 0 ? null : this.user.id }});
                const response = await vm.$axios.get("/auditoria", {
                    params: {
                        fields: "auditado,usuario,questionario",
                        usuario_id:
                            this.user.permissao == 0 ? null : this.user.id,
                    },
                });
                vm.auditorias = response.data.rows;
                _.map(vm.auditorias, (auditoria) => {
                    vm.auditados.push(auditoria.auditado);
                    vm.tiposQuestionarios.push(
                        auditoria.questionario.tipo_questionario,
                    );
                });
                vm.loading = false;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async changeAtivo(auditado) {
            const vm = this;
            try {
                const obj = _.cloneDeep(auditado);
                obj.ativo = obj.ativo ? 0 : 1;
                const response = await vm.$axios.put(
                    `/auditado/${obj.id}`,
                    obj,
                );
                auditado.ativo = response.data.ativo;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: `Auditado ${auditado.ativo ? "ativado" : "desativado"} com sucesso!`,
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar o auditado. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async remove(auditado) {
            const vm = this;
            try {
                const res = await vm.$confirm(
                    "Deseja realmente excluir?<br> Essa ação não poderá ser desfeita!",
                );
                if (!res) return;
                const response = await vm.$axios.delete(
                    `/auditado/${auditado.id}`,
                );
                vm.auditados = _.reject(vm.auditados, { id: auditado.id });
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Auditado excluído com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao excluir o auditado. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
    },
};
</script>

<style scoped></style>
